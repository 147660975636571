import { Container } from "./customPage";

export enum CategoryType {
    STANDARD = "standard",
    LOYALTY = "loyalty",
    COLLECTABLE = "collectable",
}

export enum GameMechanicType {
	"TAP_TO_PLAY" = "tap-to-play",
	"CUSTOM_GAME" = "custom-game",
}

export interface Category {
	id?: string;
	type: CategoryType | null;
	campaignID?: string;
	name: string;
	description: string;
	asset: string;
	categoryConfig: LoyaltyConfig | null;
	gameMechanicType?: GameMechanicType | null;
	gameCodeTemplateID?: string | null;
	markerTrackingID: string;
	assetSize: number;
	image: string;
	imageSize: number;
	unAllocatedComponent?: Container;
	shareable: boolean;
	sharePrizeForRewardText: string;
}
export interface LoyaltyConfig {
    enabled: boolean
    prepopulated: boolean
    repeatable: boolean
    repeatableTimes: number
    initLoyaltyVATText: string
    initLoyaltyFaceCardText: string
    endLoyaltyVATText: string
    endLoyaltyFaceCardText: string
    loyaltyVATImage: string
    loyaltyOnRedeem: boolean
    showLoyaltyVATAtStart: boolean
    showCollectedVATs: boolean
    hideLoyaltyVATAtEnd: boolean
    hideCollectedVATsAtEnd: boolean
    steps: LoyaltyStep[]
    total: number
    iterate: number
}

export interface LoyaltyStep {
    name: string
    collectableCategoryID: string
    rewardCategoryID: string
    rewardPrizeID: string
    loyaltyVATImage: string
    loyaltyVATText: string
    loyaltyFaceCardText: string
}

