class APIError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "APIError";
    }
}

class ConstraintError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "ConstraintError";
    }
}

class ProjectError extends Error {
    error?: Error;

    constructor(message: string, error?: unknown) {
        super(message);
        this.name = "ProjectError";
        if (error) {
            this.error = error instanceof Error ? error : new Error(String(error));
        }
    }

    toString() {
        if (this.error) {
            return `${this.message}\n${this.error.toString()}`;
        }
        return this.message;
    }
}

export { APIError, ConstraintError, ProjectError };
