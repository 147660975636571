import { APIClient } from "@/utils/httpClient";
import { APIError } from "@/errors";

export const getDeepLink = async (id: string): Promise<Record<string, any>> => {
    const response = await APIClient.get(`/deeplink/${id}`);
    if (response.success) {
        return response.data;
    } else {
        if (response.message) {
            throw new APIError(response.message);
        } else {
            throw new APIError("Error fetching deep link");
        }
    }
};
