import { DeepLink } from "@/types/deepLink";
import { defineStore } from "pinia";
import { vItemStore } from "./vItemStore";
import { getDeepLink } from "@/api/deepLink.api";
import { ProjectError } from "@/errors";
interface IDeepLinkStoreState {
    id: string;
    deepLink: DeepLink | null;
    isDeepLink: boolean;
}

export const deepLinkStore = defineStore("deepLinkStore", {
    state: () =>
        ({
            id: "",
            deepLink: null,
            isDeepLink: false,
        } as IDeepLinkStoreState),
    actions: {
        async start(id: string) {
            try {
                console.log("Starting deep link store: ", id);
                const deepLink = await getDeepLink(id);
                this.setDeepLink(DeepLink.fromJSON(deepLink));
                this.id = id;
                this.isDeepLink = true;
            } catch (error) {
                console.error(
                    new ProjectError("Error fetching deep link", error)
                );
            }
        },
        setDeepLink(deepLink: DeepLink) {
            this.deepLink = deepLink;
            if (deepLink.vitemID) {
                vItemStore().setDeepLinkItem(deepLink.vitemID);
            }
        },
        clear() {
            this.id = "";
            this.deepLink = null;
            this.isDeepLink = false;
        },
    },
});
