<template>
	<div id="holder">
		<iframe v-if="srcDoc != ''" id="ar-iframe" :srcdoc="srcDoc"></iframe>
		<iframe v-if="srcUrl != ''" id="ar-iframe" :src="srcUrl"
			allow="fullscreen; camera; microphone; gyroscope; accelerometer; xr-spatial-tracking"
			allowfullscreen></iframe>
		<ModalComponent :show="showClaimModal" @close="showClaimModal = false" @claim="claimItem()" />
		<LoadingComponent v-if="loading" />
	</div>
</template>

<script lang="ts">
import ModalComponent from "@/components/utils/ModalComponent.vue";
import LoadingComponent from "@/components/utils/LoadingComponent.vue";
import { getUserLocation } from "@/utils/geolocationTools";
import { Action, Container } from "@/types/customPage";
import { ARTemplate } from "@/types/arTemplate";
import { DropLocation, DropPool } from "@/types/dropPool";
import { defineComponent, PropType } from "vue";
import { navigationStore } from "@/store/pinia_modules/navigationStore";
import { dropPoolStore } from "@/store/pinia_modules/dropPoolStore";
import { vItemStore } from "@/store/pinia_modules/vItemStore";
import { authStore } from "@/store/pinia_modules/authStore";
import { poolSegmentStore } from "@/store/pinia_modules/poolSegmentStore";
import { campaignStore } from "@/store/pinia_modules/campaignStore";
import router from "@/router";
import { deepLinkStore } from "@/store/pinia_modules/deepLinkStore";

export default defineComponent({
	props: {
		element: {
			type: Object as PropType<Container>,
			required: true,
		},
	},
	components: { ModalComponent, LoadingComponent },
	data() {
		return {
			showClaimModal: false,
			selectedItemID: "",
			srcDoc: "",
			srcUrl: "",
			template: {} as ARTemplate,
			iframeElement: null as HTMLIFrameElement | null,
			loading: false,
			reachedAccuracy: false,
			initNoItems: false,
			rendered: false,
			templateHasAnimations: false,
		};
	},

	mounted() {
		const id = dropPoolStore().getWatcher;
		navigator.geolocation.clearWatch(id);
		navigationStore().isInARView();
		dropPoolStore().saveLocationAccuracy(1000000000);
		this.initUserLocation();
		this.iframeListener();
		this.checkForEnhancedAI();
	},
	computed: {
		vItems() {
			if (vItemStore().getPendingItems.length > 0) {
				return vItemStore().getPendingItems;
			}
			return vItemStore().getDroppedItems;
		},
		action(): Action {
			return navigationStore().getAction;
		},
		activePool(): DropPool | null {
			return dropPoolStore().getActivePool;
		},
		activeDropLocation(): DropLocation | null {
			return dropPoolStore().getActiveDropLocation;
		},
		loading(): boolean {
			return dropPoolStore().getLoading;
		},
		walletID(): string | null {
			return authStore().getWalletID;
		},
		userLocation(): [number, number] {
			return dropPoolStore().getUserLocation;
		},
		locationAccuracy(): number {
			return dropPoolStore().getLocationAccuracy;
		},
	},
	watch: {
		action(to: Action): void {
			if (to) {
				if (to.type == "claim-item") {
					this.claimItem();
				}
			}
		},
		locationAccuracy(to: number) {
			if (to && to <= 100 && !this.reachedAccuracy) {
				// if (to && to <= 1000000 && !this.reachedAccuracy) {
				this.reachedAccuracy = true;
				this.init();
			}
		},
		userLocation(to: [number, number]) {
			if (to) {
				if (vItemStore().getPendingItems.length == 0) {
					dropPoolStore().saveActiveDropPool();
				}
			}
		},
	},
	methods: {
		async checkForEnhancedAI() {
			if (this.initNoItems && this.vItems.length == 0) {
				this.loading = false;
				return;
			}

			if (this.vItems.length > 0 && !this.rendered) {
				//drop items here if bypass map
				this.loading = true;
				const walletID = authStore().getWalletID;
				this.rendered = true;
				if (this.activePool?.ARTemplateID) {
					this.template = await dropPoolStore().getARTemplate(
						this.activePool.ARTemplateID
					);
					if (this.template.framework == "unity") {
						this.srcUrl = this.template.srcUrl! + "/template.html";
						this.loading = false;
						return;
					}
				} else {
					const campaign = campaignStore().getItem;
					if (campaign?.pendingARTemplateID) {
						this.template = await dropPoolStore().getARTemplate(
							campaign?.pendingARTemplateID || ""
						);
					} else {
						console.error("No template found to render AR");
						this.loading = false;
						router.push(`/${campaign?.id}/vault`);
						return;
					}
				}
				const campaign = campaignStore().getItem;

				this.srcDoc = await dropPoolStore().renderARTemplate({
					walletID: walletID || "",
					arTemplateID:
						(this.activePool?.useEnhancedAI
							? this.activePool.ARTemplateID
							: campaign?.pendingARTemplateID || "legacy") || "legacy",
					dropPoolID: this.activePool?.id || "",
					deepLinkID: deepLinkStore().deepLink?.id || "",
					userLocation: this.userLocation,
				});
				this.loading = false;
			} else {
				if (this.vItems.length == 0) {
					//get user location
					this.loading = true;

					this.initNoItems = true;
					this.initUserLocation();
				}
			}
		},
		async init() {
			// if pending item, don't drop items
			if (vItemStore().getPendingItems.length == 0) {
				vItemStore().clearItems();
				dropPoolStore().saveActiveDropPool();

				await this.dropItems();
			}
			this.checkForEnhancedAI();
		},
		initUserLocation() {
			getUserLocation();
		},
		setGetLocationTimeout() {
			setTimeout(() => {
				if (!this.reachedAccuracy) {
					navigationStore().emitAction({
						type: "open-popup",
						value: "location-error",
					});
					this.loading = false;
				}
			}, 10000);
		},
		async getPoolsCategoriesPrizes() {
			await dropPoolStore().getMapData();
		},
		async getCampaignSegments() {
			poolSegmentStore().getCampaignSegments();
		},
		async dropItems() {
			// if pending item, don't drop items
			if (vItemStore().getPendingItems.length > 0) {
				return false;
			}

			if (this.activePool == null && this.activeDropLocation == null) {
				navigationStore().emitAction({
					type: "open-popup",
					value: "out-of-drop-zone",
				});

				return false;
			}

			if (this.vItems.length == 0 && this.activePool) {
				await vItemStore().recycleItems({
					walletID: this.walletID!,
					poolID: this.activePool.id!,
				});
				await vItemStore().dropItems({
					walletID: authStore().getWalletID || "",
					segmentID: "null",
					poolID: this.activePool.id!,
					userLocation: this.userLocation,
				});
			}
			return true;
		},

		selectItem(id: string) {
			console.log("Item ID /////////////////////////");
			console.log(id);
			this.selectedItemID = id;

			// When selecting item to claim, make loading transparent
			// This is especially important for deeplink claiming
			navigationStore().setLoadingTransparent(true);

			if (this.template.framework) {
				if (this.template.framework == "unity") {
					this.claimItem();
				} else {
					navigationStore().emitAction({
						type: "open-popup",
						value: "claim-item",
					});
				}
			} else {
				navigationStore().emitAction({
					type: "open-popup",
					value: "claim-item",
				});
			}
		},
		async claimItem() {
			this.loading = true;
			
			// Ensure loading is transparent during claim process
			navigationStore().setLoadingTransparent(true);
			
			let id = "";
			if (this.selectedItemID.includes("webar-marker-")) {
				id = this.selectedItemID.split("/")[1];
			} else {
				id = this.selectedItemID;
			}
			// check if item is pending
			const pendingItem = vItemStore().getPendingItems.find(
				(item) => item.id == id
			);
			try {
				await vItemStore().claimItem(id);
				this.sendMessage("itemClaimed", id);

				this.showClaimModal = false;
				this.loading = false;
				if (pendingItem && pendingItem.isDeepLink) {
				
					vItemStore().selectItem(id);
					
					// check if template has animations
					if (this.templateHasAnimations) {
						console.log("Waiting for AR animation to complete before navigation");
						await Promise.race([
							new Promise<void>((resolve) => {
								const listener = (event: MessageEvent) => {
									const data = JSON.parse(event.data);
									if (data?.event === "emitAction" && data?.message?.type === "go-to-page") {
										window.removeEventListener("message", listener);
										resolve();
									}
								};
								window.addEventListener("message", listener);
							}),
							// fallback timeout if animation takes too long
							new Promise<void>((resolve) => setTimeout(resolve, 10000)),
						]);
					} else {
						console.log("No animations in template, navigating immediately");
					}
					
					// navigate
					const campaign = campaignStore().getItem;
					router.push(`/${campaign?.id}/vault`);
				}
			} catch (error) {
				console.error(error);
				this.showClaimModal = false;
				this.loading = false;
			}
		},
		sendMessage(event: string, message: any) {
			this.iframeElement = document.getElementById(
				"ar-iframe"
			) as HTMLIFrameElement;
			const msg = JSON.stringify({
				event: event,
				message: message,
			});
			this.iframeElement!.contentWindow!.postMessage(msg, "*");
		},
		iframeListener() {
			this.iframeElement = document.getElementById(
				"ar-iframe"
			) as HTMLIFrameElement;
			window.addEventListener("message", (e) => {
				const data = JSON.parse(e.data);
				switch (data.event) {
					case "getAuth":
						const authObject = {
							campaignID: authStore().getCampaignID,
							userID: authStore().getUserID,
							walletID: authStore().getWalletID,
							refreshToken: localStorage.getItem("ReshreshToken"),
							accessToken: localStorage.getItem("AccessToken"),
						};
						this.sendMessage("auth", authObject);
						break;
					case "getDroppedItems":
						this.sendMessage("droppedItems", this.vItems);
						break;
					case "claimItem":
						try {
							// const item = JSON.parse(data.message);
							this.selectItem(data.message);
						} catch (err) {
							console.error(err);
						}
						break;
					case "alreadyClaimed":
						navigationStore().emitAction({
							type: "open-popup",
							value: "already-claimed",
						});
						break;
					case "templateInit":
						// Initialize template configuration
						console.log("AR template initialized with config:", data.message);
						if (data.message && typeof data.message.hasAnimations === 'boolean') {
							this.templateHasAnimations = data.message.hasAnimations;
						}
						break;
					case "emitAction":
						navigationStore().emitAction(data.message);
						break;
				}
			});
		},
	},
});
</script>

<style scoped lang="scss">
#holder {
	width: 100%;
	height: 100%;
	background-color: #000;
}

#ar-iframe {
	width: 100%;
	height: 100%;
	border-style: none;
	background-color: #000;
}

a-scene {
	width: 100%;
	height: 100%;
}

.ar-loading {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: #000;
	z-index: 100;
}

.cross-hair {
	position: absolute;
	top: 50%;
	left: 50%;

	.hair {
		width: 200px;
		border-style: solid;
		border-width: 1px 0 0 0;
		border-color: #fff;
		position: absolute;
		transform: translate(-50%, -50%);
		opacity: 0.2;
	}

	.vertical {
		transform: rotate(90deg);
		left: -100px;
	}
}
</style>
