export class DeepLink {
    id: string;
    campaignID: string;
    externalCustomerID: string;
    vatCategoryID: string;
    voucherCode: string;
    vitemID: string;
    createdAt: string;
    updatedAt: string;

    constructor(data: Partial<DeepLink>) {
        this.id = data.id || "";
        this.campaignID = data.campaignID || "";
        this.externalCustomerID = data.externalCustomerID || "";
        this.vatCategoryID = data.vatCategoryID || "";
        this.voucherCode = data.voucherCode || "";
        this.vitemID = data.vitemID || "";
        this.createdAt = data.createdAt || "";
        this.updatedAt = data.updatedAt || "";
    }

    static fromJSON(json: Record<string, any>): DeepLink {
        return new DeepLink(json);
    }
}
