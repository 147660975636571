import { deepLinkStore } from "@/store/pinia_modules/deepLinkStore";
import { CategoryType } from "./category";

export enum GameMechanicType {
    TAP_TO_PLAY = "tap-to-play",
    CUSTOM_GAME = "custom-game",
}

export enum ShareStatus {
    SHARED = "shared",
    PENDING = "pending",
    NOT_SHARED = "not-shared",
}

export enum PrizeStatus {
    ALLOCATED = "allocated",
    UNALLOCATED = "unallocated",
    REDEEMED = "redeemed",
    EXPIRED = "expired",
}

export enum ClaimErrorStatus {
    NOT_FOUND_ERROR = "NOT_FOUND_ERROR",
    MAX_ITEMS_CLAIMED = "MAX-ITEMS-CLAIMED",
    VOUCHER_FAILED = "VoucherFailed",
    VOUCHER_USER_LIMIT = "VoucherUserLimit",
    VOUCHER_OUT_OF_CODES = "VoucherOutOfCodes",
}

export enum ItemStatus {
    UNKNOWN = "unknown",
    DROPPED = "dropped",
    CLAIMED = "claimed",
    ALLOCATED = "allocated",
    REDEEMED = "redeemed",
    EXPIRED = "expired",
    NOPRIZE = "no-prize",
    PENDING = "pending",
}

export class VItem {
    id: string;
    campaignID: string;
    segmentID: string;
    categoryID: string;
    dropPoolID: string;
    walletID: string;
    merchantWalletID: string;
    prizeID: string;
    prizeStatus: PrizeStatus;
    voucherCode: string;
    voucherCodeID: string;
    latitude: number;
    longitude: number;
    loyaltyID: string | null;
    itemStatus: ItemStatus;
    dropDate: string;
    claimDate: string | null;
    redeemDate: string | null;
    allocatedAt: string;
    winning: boolean;
    shareStatus: ShareStatus;
    shareID: string;
    createdAt: string;
    updatedAt: string;
    // Category Details
    type: CategoryType;
    marker: string;
    asset: string;
    categoryConfig: LoyaltyConfig | null;
    loyaltyState: LoyaltyState | null;
    error?: string;
    // local state
    isDeepLink: boolean;

    constructor(data: Partial<VItem>) {
        this.id = data.id || "";
        this.campaignID = data.campaignID || "";
        this.segmentID = data.segmentID || "";
        this.categoryID = data.categoryID || "";
        this.dropPoolID = data.dropPoolID || "";
        this.walletID = data.walletID || "";
        this.merchantWalletID = data.merchantWalletID || "";
        this.prizeID = data.prizeID || "";
        this.prizeStatus = data.prizeStatus || PrizeStatus.UNALLOCATED;
        this.voucherCode = data.voucherCode || "";
        this.voucherCodeID = data.voucherCodeID || "";
        this.latitude = data.latitude || 0;
        this.longitude = data.longitude || 0;
        this.loyaltyID = data.loyaltyID || null;
        this.itemStatus = data.itemStatus || ItemStatus.UNKNOWN;
        this.dropDate = data.dropDate || "";
        this.claimDate = data.claimDate || null;
        this.redeemDate = data.redeemDate || null;
        this.allocatedAt = data.allocatedAt || "";
        this.winning = data.winning || false;
        this.shareStatus = data.shareStatus || ShareStatus.NOT_SHARED;
        this.shareID = data.shareID || "";
        this.createdAt = data.createdAt || "";
        this.updatedAt = data.updatedAt || "";
        this.type = data.type || CategoryType.STANDARD;
        this.marker = data.marker || "";
        this.asset = data.asset || "";
        this.categoryConfig = data.categoryConfig || null;
        this.loyaltyState = data.loyaltyState || null;
        this.error = data.error || undefined;
        // local state
        if (deepLinkStore().deepLink?.vitemID == this.id) {
            this.isDeepLink = true;
        } else {
            this.isDeepLink = false;
        }
    }

    static fromJSON(json: Record<string, any>): VItem {
        return new VItem(json);
    }

    static fromJSONArray(jsonArray: any[]): VItem[] {
        return jsonArray.map((json) => VItem.fromJSON(json));
    }

    updateFromJSON(json: Record<string, any>): void {
        Object.assign(this, VItem.fromJSON(json));
    }
}

export interface LoyaltyConfig {
    enabled: boolean;
    prepopulated: boolean;
    repeatable: boolean;
    repeatableTimes: number;
    initLoyaltyVATText: string;
    initLoyaltyFaceCardText: string;
    endLoyaltyVATText: string;
    endLoyaltyFaceCardText: string;
    loyaltyVATImage: string;
    loyaltyOnRedeem: boolean;
    onRedeem: boolean;
    showLoyaltyVATAtStart: boolean;
    showCollectedVATs: boolean;
    hideLoyaltyVATAtEnd: boolean;
    hideCollectedVATsAtEnd: boolean;
    steps: LoyaltyStep[];
    total: number;
    iterate: number;
}

export interface LoyaltyStep {
    name: string;
    collectableCategoryID: string;
    rewardCategoryID: string;
    rewardPrizeID: string;
    loyaltyVATImage: string;
    loyaltyVATText: string;
    loyaltyFaceCardText: string;
}

export interface LoyaltyState {
    complete: boolean;
    collected: number;
    collectedList: string[];
    unCollectedList: string[];
    total: number;
    initText: string;
    image: string;
}
